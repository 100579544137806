/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ProductImageVertical.module.scss';
import { CloudeFlare } from '../../../../constants';

/* const BadgesImageContainer = ({
  bankBadge, eventBadge, children, ...props
}) => {
  const hasBankBadge = bankBadge?.value?.length > 0;
  const hasGarantieBadge = props.garantieBadge?.value?.length > 0;
  const hasDiscountBadge = props.discountBadge?.value?.length > 0;
  return children({
    hasBankBadge, hasGarantieBadge, hasDiscountBadge,
  });
}; */

const ProductImageVertical = ({
  sku, name, badges, className, onClick,
}) => {
  const [isImageBroken, setIsImageBroken] = useState(false);
  const placeholderImageUrl = 'https://images.contentstack.io/v3/assets/blt2f8082df109cfbfb/blt986a90bb4eedcd35/664769a80bbc6249927fa5a5/404.jpeg';

  const handleImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <button
      type="button"
      className={classNames(style.imageVertical, className)}
      onClick={onClick}
    >
      {badges}
      <picture>
        <source media="(max-width: 720px)" srcSet={`${CloudeFlare}/${sku}/preview`} />
        <source media="(max-width: 1024px)" srcSet={`${CloudeFlare}/${sku}/preview`} />

        {isImageBroken ? (
          <img src={placeholderImageUrl} alt="Imagen de respaldo" />
        ) : (
          <img
            className={style.imageVertical__image}
            src={`${CloudeFlare}/${sku}/w=200,h=200,f=webp,fit=contain,q=85`}
            alt={name}
            onError={handleImageError}
          />
        )}
      </picture>
    </button>
  );
};

ProductImageVertical.propTypes = {
  name: PropTypes.string,
  sku: PropTypes.string,
  badges: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default ProductImageVertical;
